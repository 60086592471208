.upper_cards_section {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.upper_cards_section .card {
  margin-left: 8px;
  color: white;
  padding: 10px;
  border-radius: 25px;
  width: 23%;
}

.upper_cards_section .icon-wrapper {
  margin: 10px;
  height: 70%;
  width: 100%;
  text-align: center;
  padding: 10px;
  border: 2px solid white;
  border-radius: 25px;
}

.upper_cards_section .card-title {
  margin-top: 10px;
}

.upper_cards_section .card-value {
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
}
